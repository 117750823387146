import { createTheme, Theme } from '@material-ui/core/styles'
import createPalette from '@material-ui/core/styles/createPalette'
import _, { set } from 'lodash'
import { typography } from './theme-v2-definitions'

export const palette = createPalette({
  primary: {
    dark: '#003E7E',
    light: '#1370BC',
    main: '#232735',
  },
  secondary: {
    dark: '#003E7E',
    main: '#1370BC',
    light: '#f3f6f9',
  },
  text: {
    primary: '#232735',
    secondary: '#505565',
    hint: '#6E7280',
  },
  error: {
    main: '#FF0202',
  },
  divider: '#E9E9EB',
  action: {
    hover: '#f3f6f9',
    selected: '#f3f6f9',
  },
  grey: {
    100: '#F9F9F9',
    200: '#EFECEC',
    700: '#8B90A0',
    900: '#505565',
  },
  background: {
    default: '#FFFFFF',
  },
})

// Material seems to miss some component props in its types
const trickTypes = {}
set(trickTypes, 'component', 'div')

const defaultTheme = createTheme({
  palette,
  typography,
})

const partialTheme: Theme = {
  ...defaultTheme,
  props: {
    MuiButtonBase: {
      disableRipple: false,
    },
    MuiButton: {
      size: 'large',
      color: 'secondary',
    },
    MuiDialogTitle: {
      disableTypography: false,
    },
    MuiInputLabel: {
      color: 'secondary',
      disableAnimation: true,
      shrink: true,
    },
    MuiExpansionPanel: {
      TransitionProps: {
        unmountOnExit: true,
      },
    },
    MuiFormLabel: {
      focused: false,
    },
    MuiMenuList: trickTypes,
    MuiTable: trickTypes,
    MuiTableHead: trickTypes,
    MuiTableBody: trickTypes,
    MuiTableRow: trickTypes,
    MuiTablePagination: trickTypes,
    MuiTableCell: {
      component: 'div',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#FFF',
          fontWeight: undefined,
        },
      },
    },
    MuiAvatar: {
      square: {
        borderRadius: 4,
      },
    },
    MuiIconButton: {
      edgeEnd: {
        color: palette.grey[700],
      },
    },
    MuiButton: {
      contained: {
        boxShadow: undefined,
        '&:hover': {
          boxShadow: undefined,
        },
        '&:active': {
          boxShadow: undefined,
        },
        '&:focus': {
          boxShadow: undefined,
        },
      },
      containedPrimary: {
        color: palette.common.white,
        backgroundColor: palette.primary.light,
        '&.Mui-disabled': {
          color: '#FFF',
        },
      },
      containedSecondary: {
        '&.Mui-disabled': {
          color: '#FFF',
        },
      },
      outlinedPrimary: {
        color: palette.primary.main,
        borderColor: palette.primary.main,
      },
      root: {
        borderRadius: 4,
        fontWeight: 500,
        fontSize: '1rem',
        textTransform: 'none',
      },
    },
    MuiCheckbox: {
      root: {
        color: '',
        padding: 0,
      },
    },
    MuiChip: {
      deleteIcon: {
        color: 'inherit',
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: 20,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        maxHeight: 360,
        minHeight: 300,
        padding: 0,
      },
    },
    MuiExpansionPanelSummary: {
      content: {
        alignItems: 'center',
      },
      root: {
        marginBottom: 16,
        marginTop: 16,
      },
    },
    MuiFormControl: {
      root: {
        display: 'flex' /* Input fields should fill all available space */,
        width: '100%',
      },
    },
    MuiInput: {
      inputMarginDense: {
        [defaultTheme.breakpoints.down('md')]: {
          padding: defaultTheme.spacing(1, 2),
        },
      },
      underline: {
        '&:before': {
          borderBottomColor: palette.grey[700],
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottomColor: palette.primary.light,
        },
        '&.Mui-focused': {
          '&:after': {
            borderBottomColor: palette.primary.light,
          },
        },
      },
      formControl: {
        marginTop: '0 !important',
      },
    },
    MuiInputBase: {
      input: {
        backgroundColor: palette.grey[100],
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        padding: '11px 12px 12px',
        outline: 'none !important',
        '&::placeholder': {
          color: '#979797',
          fontWeight: 'normal',
          opacity: 1,
        },
        '&:focus': {
          outlineColor: palette.text.primary,
          outlineStyle: 'auto',
          outlineWidth: 1,
        },
      },
      inputMultiline: {
        padding: '4px 8px',
      },
      root: {
        color: palette.text.primary,
        '&.Mui-error': {
          color: palette.error,
        },
      },
      adornedEnd: {
        backgroundColor: palette.grey[100],
        padding: defaultTheme.spacing(0, 1),
      },
      adornedStart: {
        backgroundColor: palette.grey[100],
        padding: defaultTheme.spacing(0, 1),
      },
    },
    MuiInputLabel: {
      /* Turn off inputLabel animation and transformation */
      formControl: {
        position: 'relative',
        transform: 'none',
      },
      root: {
        fontSize: '1rem',
        marginBottom: defaultTheme.spacing(1),
      },
      shrink: {
        transform: 'none',
      },
    },
    MuiLink: {
      root: {
        textTransform: 'uppercase',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          color: palette.text.primary,
        },
      },
      root: {
        '&.Mui-selected': {
          color: '#52575C',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit' /* Inheriting the color is better than just being black by default */,
        minWidth: 32,
      },
    },
    MuiListItemText: {
      root: {
        maxWidth: '100%', // IE11
      },
    },
    MuiMenu: {
      paper: {
        border: '1px solid #EDEAEA',
        boxShadow: '0px 0px 8px rgba(37, 40, 43, 0.12)',
        paddingTop: defaultTheme.spacing(1),
        paddingBottom: defaultTheme.spacing(1),
        '&::-webkit-scrollbar': {
          background: 'transparent',
          width: defaultTheme.spacing(1),
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#7F7F7F',
          borderRadius: defaultTheme.spacing(0.5),
        },
      },
    },
    MuiMenuItem: {
      root: {
        paddingBottom: '0.75em',
        paddingTop: '0.75em',
        color: palette.grey[900],

        '&:hover': {
          backgroundColor: '#F3F6F9',
          color: palette.grey[900],
        },
      },
    },
    MuiSelect: {
      icon: {
        bottom: 9, // for IE11
        top: 'auto',
      },
      root: {},
      select: {
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
      },
      selectMenu: {
        alignItems: 'center',
        display: 'flex',
      },
    },
    MuiTab: {
      root: {
        display: 'flex',
        minHeight: 36,
        flexDirection: 'row',
        alignItems: 'center',
        textTransform: 'none',
        fontWeight: 600,
        fontSize: 16,
        '&.Mui-selected': {
          zIndex: 25,
          color: palette.primary.light,
          borderBottomWidth: 2,
          borderBottomStyle: 'solid',
          borderBottomColor: palette.primary.light,
        },
        '&.Mui-selected .MuiSvgIcon-root': {
          fill: palette.primary.light,
        },
      },
      labelIcon: {
        marginRight: 10,
      },
      wrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > div': {
          width: 30,
          height: 18,
        },
      },
    },
    MuiTabs: {
      root: {
        minHeight: 36,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: palette.grey[200],
      },
      indicator: {
        zIndex: 2,
        backgroundColor: '#fff',
      },
    },
    MuiTable: {
      root: {
        border: 'none',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        [defaultTheme.breakpoints.down('md')]: {
          padding: 8,
        },
      },
    },
    MuiTableBody: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
      },
    },
    MuiTableCell: {
      head: {
        width: 'fit-content',
        paddingBottom: defaultTheme.spacing(1),
        paddingTop: defaultTheme.spacing(1),
        color: palette.grey[700],
        fontWeight: 500,
      },
      body: {
        color: '#636363',
        fontWeight: 400,
      },
      alignRight: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      },
      footer: {
        color: '#979797',
        fontWeight: 'bold',
      },
      root: {
        alignItems: 'flex-start',
        borderBottom: 'none',
        display: 'flex',
        flexBasis: 200,
        flexGrow: 1,
        flexShrink: 0,
        [defaultTheme.breakpoints.down('md')]: {
          padding: 4,
        },
      },
    },
    MuiTableFooter: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#edeaea',
      },
    },
    MuiTableHead: {
      root: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    MuiTableRow: {
      footer: {
        flexDirection: 'row-reverse',
      },
      root: {
        width: '100%',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: palette.divider,
        backgroundColor: palette.common.white,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        flexShrink: 0,
        '&$selected': {
          backgroundColor: 'rgba(0, 62, 126, 0.1)',
        },
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
    MuiTooltip: {
      arrow: {
        color: palette.text.primary,
      },
      tooltip: {
        backgroundColor: palette.text.primary,
        fontSize: '0.8rem',
      },
    },
    MuiDialog: {
      root: {
        [defaultTheme.breakpoints.down('sm')]: {
          padding: 0,
        },
      },
      paper: {
        borderRadius: defaultTheme.spacing(1),
        [defaultTheme.breakpoints.down('sm')]: {
          margin: defaultTheme.spacing(1),
        },
      },
      paperFullWidth: {
        [defaultTheme.breakpoints.down('sm')]: {
          padding: defaultTheme.spacing(4, 1),
          width: `calc(100% - ${defaultTheme.spacing(2)})`,
        },
        [defaultTheme.breakpoints.up('md')]: {
          padding: defaultTheme.spacing(3),
        },
        [defaultTheme.breakpoints.down('md')]: {
          padding: defaultTheme.spacing(1),
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: defaultTheme.spacing(3),
      },
    },
    MuiTablePagination: {
      caption: {
        fontSize: '14px',
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: palette.primary.light,
      },
      colorSecondary: {
        color: '#fff',
      },
    },
    MuiSvgIcon: {
      colorDisabled: {
        color: palette.grey[700],
      },
    },
  },
}

export const theme = createTheme({
  ...defaultTheme,
  ..._.set(partialTheme, ['overrides'], {
    ...partialTheme.overrides,
    MuiPickersDay: {
      dayDisabled: {
        color: 'lightgrey',
      },
    },
    MuiTabPanel: {
      root: {
        padding: defaultTheme.spacing(0),
      },
    },
  }),
})
